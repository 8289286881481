import classes from './Slider.module.css'

import img0 from '../assets/sneak/0.png'
import img1 from '../assets/sneak/1.png'
import img2 from '../assets/sneak/2.png'
import img3 from '../assets/sneak/3.png'
import img4 from '../assets/sneak/4.png'
import img5 from '../assets/sneak/5.png'
import img6 from '../assets/sneak/6.png'
import img7 from '../assets/sneak/7.png'
import img8 from '../assets/sneak/8.png'
import img9 from '../assets/sneak/9.png'

const Slider = () => {
  let temp = []
  let images = [img0, img1, img2, img3, img4, img5, img6, img7, img8, img9]
  for (let i = 0; i < 10; i++) {
    temp.push(<img src={images[i]} className="w-1/3 md:w-1/6 inline-block"/>)
  }

  temp = [...temp, ...temp]

  return (
    <div className="relative bg-main filter shadow-2xl overflow-x-hidden">
      <div className={`${classes.scoller} whitespace-nowrap w-full transition-all duration-300`}>
        { temp }
      </div>
    </div>
  )
}

export default Slider