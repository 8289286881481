import earthImage from '../assets/earth.png'
import classes from './Faq.module.css'

const Faq = () => {
  const questions = [
    {
      q: 'What are Space Explorers?',
      a: 'A team of 1410 astronauts sent on a mission to discover new habitable places in our universe.'
    },
    {
      q: 'When launch?',
      a: 'The mint will take place around December 15. (more info soon)'
    },
    {
      q: 'How much will it cost to mint an NFT?',
      a: 'The price of the mint will be 0.80 SOL'
    },
    {
      q: 'How many NFTs will there be?',
      a: 'There will be 1410 Space Explorers in the galaxy'
    },
    {
      q: 'Will there be a pre-sale?',
      a: 'Yes, there will be a 50 NFTs presale a few days before the main mint.'
    },
    {
      q: 'Where can I buy Space Explorers on the secondary market?',
      a: 'You will be able to exchange your NFTs on marketplaces like MagicEden.io, Alpha.art...(more info soon)'
    },
    {
      q: 'Do you have a rarity system?',
      a: 'Yes, a rarity system will be available soon after the mint.'
    },
    {
      q: 'Which wallets will be available?',
      a: 'We recommend using Phantom Wallet, but Sollet.io and Solflare are also available.'
    }
  ]

  return (
    <div id="faq" className="xl:h-screen flex justify-center items-center relative overflow-hidden py-20 xl:py-0">
      <div className="hidden xl:flex justify-start items-center h-full filter brightness-50 pl-4 2xl:pl-0">
        <img src={earthImage} alt="Earth" className="w-2/3"/>
      </div>
      <div className="flex flex-col lg:w-11/12 xl:w-4/5 2xl:w-3/5 z-20 pl-4 md:pl-20 lg:pl-0 pr-4 md:pr-20 lg:pr-4 2xl:pl-0 text-center lg:text-left ">
        <h1 className="text-6xl font-bold un">FAQ</h1>
        <div className="flex flex-col items-center lg:items-start lg:flex-row flex-wrap pt-4">
          <div className="flex flex-col flex-wrap flex-1 lg:mr-2">
            {questions.slice(0, Math.ceil(questions.length / 2)).map((el, i) => {
              return (
                <div className={`${classes.q} q bg-gradient-to-br from-close-black to-black filter shadow-2xl rounded-2xl p-6 my-2 pl-12 relative flex-1`}>
                  <span className="absolute top-6 left-6 text-main text-xl">{i + 1}.</span>
                  <h4 className="text-main text-xl">{el.q}</h4>
                  <p>{el.a}</p>
                </div>
              )
            })}
          </div>
          <div className="flex flex-col flex-wrap flex-1 lg:ml-2">
            {questions.slice(-Math.ceil(questions.length / 2)).map((el, i) => {
              return (
                <div className={`${classes.q} q bg-gradient-to-br from-close-black to-black filter shadow-2xl rounded-2xl p-6 my-2 pl-12 relative flex-1`}>
                  <span className="absolute top-6 left-6 text-main text-xl">{i + Math.ceil(questions.length / 2) + 1}.</span>
                  <h4 className="text-main text-xl">{el.q}</h4>
                  <p>{el.a}</p>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Faq