import venusImage from '../assets/venus.png'

const Roadmap = () => {
  return (
    <div id="roadmap" className="h-screen flex justify-around items-center relative overflow-hidden">
      <div className="flex flex-col w-11/12 lg:pl-4 xl:pl-0 xl:w-2/5 z-20 text-center lg:text-left">
        <h1 className="text-6xl font-bold">Roadmap</h1>
        <div className="flex flex-col w-full pt-4">
          <p className="pb-4">Our main goal is to create a bluechip project. That's why, if you are only here to see the roadmap, then you are probably not in the right place. We want to build a strong community, motivated and ready to hold their NFTs for the long term. Here is a small overview of the rewards for Space Explorers :</p>
          <div className="flex flex-col border-b border-gray-800 pb-4">
            <div>
              <h2 className="text-2xl lg:text-4xl font-semibold">Merry Christmas</h2>
            </div>
            <p>The magic of Christmas sparkles even in space. For 7 days, we'll be offering our holders daily rewards, from simple airdrops to huge gifts.</p>
          </div>
          <div className="flex flex-col py-4 border-b border-gray-800">
            <div>
              <h2 className="text-2xl lg:text-4xl font-semibold">Space Station Game</h2>
            </div>
            <p>ALL space explorers have a personal living studio in their space station. Imagine being able to explore it but......also customize it. SOON! (We are so hyped omg!)</p>
          </div>
          <div className="flex flex-col pt-4">
            <div>
              <h2 className="text-2xl lg:text-4xl font-semibold">The New Chapter (more info soon)</h2>
            </div>
            <p>Probably the most important event!</p>
          </div>
        </div>
      </div>
      <div className="hidden lg:flex justify-end items-center pr-4 xl:pr-0 h-full">
        <img src={venusImage} alt="Venus" className="w-2/3"/>
      </div>
    </div>
  )
}

export default Roadmap