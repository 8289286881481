
import classes from './About.module.css'

const About = () => {
  return (
    <div id="about" className="h-screen flex justify-center items-center relative overflow-hidden">
      <div className="flex flex-col w-11/12 lg:w-2/3 xl:w-1/2 2xl:w-2/5 z-20 text-center lg:text-left">
        <h1 className="text-6xl font-bold un">About</h1>
        <p className="py-4">A team of 1421 astronauts, otherwise known as the Space Explorers, has been sent into the space to discover new habitable planets and new forms of life in the far reaches of our universe.
          <br />
          Throughout their expedition aboard their ship, the space explorers will make many mysterious discoveries. Courageous, motivated and ambitious are 3 adjectives that perfectly define our travelers who have only one objective, to discover the wonders of our universe.
          <br />
          Stay tuned on our twitter account and on our discord, major events and information could soon appear.
        </p>
        <button className="rounded-full min-w-full font-main px-4 py-4 cursor-not-allowed bg-gradient-to-r from-accents to-accents-2 text-2xl justify-center font-semibold text-white">JOIN THE COMMUNITY</button>
      </div>
      <div className={classes.sun}></div>
    </div>
  )
}

export default About