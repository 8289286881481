import * as anchor from "@project-serum/anchor";

import Intro from './pages/Intro'
import Slider from './pages/Slider'
import About from './pages/About'
import Roadmap from './pages/Roadmap'
import Faq from './pages/Faq'

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}

const NewHome = (props: HomeProps) => {
  return (
    <div className="relative">
      <Intro
        candyMachineId={props.candyMachineId}
        config={props.config}
        connection={props.connection}
        startDate={props.startDate}
        treasury={props.treasury}
        txTimeout={props.txTimeout}
      />
      <Slider />
      <About />
      <Roadmap />
      <Faq />
    </div>
  )
}

export default NewHome