import Particles from "react-tsparticles"
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui"
import { WalletDisconnectButton } from "@solana/wallet-adapter-material-ui"
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { ThemeProvider } from 'styled-components'
import classes from './Intro.module.css'
import { options } from '../assets/starts'
import Home from '../Home'

import logo from '../assets/logo.png'
import img from '../assets/no_bg.png'

import * as anchor from "@project-serum/anchor";

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}

const Intro = (props: HomeProps) => {
  const wallet = useAnchorWallet()
  return (
    <div id="mint" className="relative">
      <div className="w-screen h-screen md:px-20 flex justify-center">
        <div className="absolute transform -translate-x-1/2 left-1/2 w-full px-8 xl:px-0 xl:w-4/5 2xl:w-2/3 flex justify-between items-center h-28 z-20">
          <img src={logo} alt="Logo" className="h-28 py-4"/>
          <div className="hidden lg:block absolute transform -translate-x-1/2 left-1/2">
            <ul className="flex space-x-20">
              <a href="#mint"><li>Mint</li></a>
              <a href="#about"><li>About us</li></a>
              <a href="#roadmap"><li>Roadmap</li></a>
              <a href="#faq"><li>FAQ</li></a>
            </ul>
          </div>
            {!wallet && <WalletDialogButton className={classes.connectBtn}>Connect</WalletDialogButton>}
            {wallet && <WalletDisconnectButton className={classes.connectBtn}>Disconnect</WalletDisconnectButton>}
        </div>
        <div className="w-11/12 lg:w-4/5 xl:w-2/3 h-full flex justify-center md:justify-between items-center z-10">
            <div className="lg:w-1/2 2xl:w-2/5 flex flex-col">
              <h1 className="text-5xl 2xl:text-6xl font-bold">SPACE EXPLORERS</h1>
              <p className="py-4">A collection of 1410 unique NFTs deployed on the Solana blockchain, randomly generated from 43 attributes.<br/>Join our community of explorers and find out what lies within our universe. Discovering the unknown is a very exciting thing isn't it?</p>
              <Home
                candyMachineId={props.candyMachineId}
                config={props.config}
                connection={props.connection}
                startDate={props.startDate}
                treasury={props.treasury}
                txTimeout={props.txTimeout}
              />
              {/* <div className="rounded-full w-1/2 px-4 py-4 cursor-not-allowed bg-gradient-to-r from-accents to-accents-2 text-2xl text-center font-semibold">
              </div> */}
            </div>
            <img src={img} alt="" className="hidden lg:block w-1/3"/>
        </div>
      </div>
      <Particles className="opacity-40 absolute h-screen top-0" id="tsparticles" options={options} />
    </div>
  )
}

export default Intro